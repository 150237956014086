.top-section{
  color: #083f88;
  font-size: clamp(15px, 2.5vw, 40px) !important;;
  display: flex;
  flex-direction: column;
  padding-top:40px;
  text-align: center;
}
.top-section>h2{
  font-weight: 600;
}

@media screen and (min-width:600px) and(max-width: 991.9px){
  .top-section {
      padding-top: 40px !important;
      
  }
  
   }
  
@media screen and (max-width: 600px) {
  .top-section {
    font-size: clamp(10px, 4vw, 30px) !important;
}
.posts {
  margin-top: 40px;
margin-bottom: 50px;

}
}
@media screen and (max-width: 991.9px) {
  /* .top-section{
      padding-top:20px;
     
  } */
  .top-section>h2{
    font-weight: 600;
}
}
  @media screen and (max-width: 575px) {
      .top-section{
          padding-top:0px;
        
      }
}

